import React from 'react';
import "./footer.css";
import { FaReact } from 'react-icons/fa';

export const Footer = () => {
  return (
    <div className="container alt footer">
      <div className="bar">
        <p className="footer-notice">
          © {new Date().getFullYear()} Andy Anderson &nbsp; | &nbsp; Built with{" "}
          <a href="https://react.dev/" target="_blank" rel="noopener noreferrer">React.js</a>{" "}
          <FaReact size={20} className="react-icon"/>
        </p>
      </div>
    </div>
  );
};

export default Footer;
